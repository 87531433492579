import React, { useEffect } from 'react'
import './HomePage.scss';
import HomeTemplate from '../templates/home-template/HomeTemplate'
import HomeHero from '../organisms/home-hero/HomeHero'
import WhiteBodyTemplate from '../templates/whitebody-template/WhiteBodyTemplate'
import Header from '../organisms/header/Header'
import Footer from '../organisms/footer/Footer';
import MobileHeader from '../organisms/header/MobileHeader';
import { useNavigate } from 'react-router';



function HomePage() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Hidden T - Fishing Rod Handle Comfort Gimbal Component";
  }, []);


  return (
    <HomeTemplate>
      
        {/* <div className='komfort-stik-topmost'>
          ICAST 2024 - Click here for the <img src="/images/komfort-stik-logo.png" className='komfort-small-logo' alt="komfort-stik-logo" /> Made in the USA <img className='america' src='/flag.jpg' />
        </div> */}

        <HomeHero />
        
        <WhiteBodyTemplate>
        
        <Header currentPage='home' isHome={true} />
        <MobileHeader currentPage='home' isHome={true} />


          <div className='competetive-edge-wrap'>
            <div className='container'>
            <div className='large-image'>
              <img src='/images/competitive-edge.jpg?cache=4-02-2024' alt='placeholder' />
            </div>
            <div className='content'>
              <h1>NEED A <b>COMPETITIVE EDGE?</b></h1>
              <p>Introduce a HIDDEN T Handle Component Rod Model into your rod line up now. Enhance your products desirability and  Increase profits. </p>
            </div>
            </div>
          </div>

          <div className='patent-pending-wrap'>
            <img src='/images/patent-pending.png?cache=05-17-2024' />
          </div>

          <div className='designed-by-fisherman-wrap'>
            <div className='content'>
              <h1>DESIGNED BY A SEASONED CAPTAIN, FOR EVERY FISHERMAN.</h1>
              <p>Expensive Rubber cushion soiled or lost? Belt stowed away? Not a problem with HIDDEN T. Never Lose it. When you need it use it. Snap to Fight, Snap to store. Hidden T gives you so much more.</p>
              <div className='seal-of-approval'>
                <img src='/images/seal-of-approval.png' />
              </div>
            </div>
            <div className='large-image'>
            </div>
          </div>

          <div className='hiddent-advantages-wrap'>

            <div className='long-heading'>
            HIDDEN T - FISHING ROD HANDLE COMFORT GIMBAL COMPONENT
            </div>

            <div className='content-block image-left'>
              <div className='content-sizer'>
            <div className='large-image' style={{
              borderRadius: `0px`
            }}>
                  {/* <img src='/images/advantage-1.jpg?cache=3-23-2024' alt='placeholder' /> */}
                   <img src='/images/new-hiddent.jpg?cache=4-02-2024' alt='placeholder' style={{
                    height: `53vh`,
                
                   }} />
                </div>
                <div className='content'>
                  <div className='text-limit'>After a big day of deep-water fishing, you'll be glad you had the HIDDEN T Advantage.</div></div>
                </div>
            </div>
            <div className='content-block image-right'>
            <div className='content-sizer'>
                <div className='content'><div className='text-limit'>"This is a Must Have". Buy it already installed on a new rod or retro fit it to your favorite old rod. It's easy to install. Ask your dealer for HIDDEN T.</div></div>
                <div className='large-image'>
                  <img src='/images/advantage-2.jpg?cache=4-02-2024' alt='placeholder' />
                </div>
                </div>
            </div>
          </div>

          <div className='retro-fit-wrap'>
            <div className='content'>
              <h1>Retro-Fit it to almost any Rod. When Sport fishing, you're always ready to fight the Big One.</h1>
              <p>Ask for HIDDEN T Before Fish Hit, Retro-Fit with HIDDEN T.<br />The Comfortable Fishing Advantage. </p>
            </div>
          </div>

          <div className='available-wrap'>
            <div className='sizer'>
            <div className='content'>
              <h1>HIDDEN T<b>FIGHTING BUTT COMPONENT</b></h1><br />
             Made of POM Tough Plastics
              <p>Everyone's talking about the Hidden T Component.  Always Ready with HIDDEN T!  Finally, a solution to an Age-old problem.</p>

              {/* <div className='buynow-button'>
                <a href='https://favoritecreative.com/hiddent/favoriteCMS/api/store_redirect.php' target='_blank'>BUY NOW</a>
              </div> */}
            </div>
            <div className='large-image'>
              <img src='/images/units_new.jpg?cache=04-04-2024' alt='placeholder' />
            </div>
            </div>
          </div>

          <div className='showoff-video'>
            {/* <div className='video'>
            <div><iframe src="https://player.vimeo.com/video/837443140?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=false&portrait=0&byline=0" frameBorder={0} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="IMG_2137"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            </div> */}
            <div className='video'>
            <div><iframe src="https://player.vimeo.com/video/843280980?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=false&portrait=0&byline=0" frameBorder={0} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="IMG_5955"></iframe></div>
            </div>
          </div>

          {/* <div className='large-showoff'>
          <div className='video'>
            <div><iframe src="https://player.vimeo.com/video/926623391?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=false&portrait=0&byline=0" frameBorder={0} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="IMG_5955"></iframe></div>
            </div>
          </div> */}


          

         
         <Footer />

        </WhiteBodyTemplate>
    </HomeTemplate>
  )
}

export default HomePage