import React, { createContext } from 'react'

const ConfigContext = createContext<ConfigContextValue>(null as any)

interface ConfigContextProps {
    children: any
}
interface ConfigContextValue {
    phoneNumber: string,
    phoneNumberFormatted: string,
    email: string,
    headerItems: menuItemProps[],
    hasUpdatedCounter: boolean,
    updateCounter: () => void
    adminLoggedIn: boolean
    adminLogin: () => void;
}
interface menuItemProps {
    name: string,
    link: string,
    identifier: string,
    external?: boolean,
    onlyMobile?: boolean
}

function ConfigContextProvider(props: ConfigContextProps) {
    const menuItems: menuItemProps[] = [
        {
            name: 'Home',
            link: '/',
            identifier: 'home'
        },
        {
            name: 'About',
            link: '/about-us',
            identifier: 'about-us'
        },
        {
            name: 'Gallery',
            link: '/gallery',
            identifier: 'gallery'
        },
        {
            name: 'Installation',
            link: '/installation',
            identifier: 'installation'
        },
        {
            name: 'Contact Us',
            link: '/contact-us',
            identifier: 'contact-us'
        },
        // {
        //     name: 'Buy Now',
        //     link: 'https://favoritecreative.com/hiddent/favoriteCMS/api/store_redirect.php',
        //     identifier: 'buy-now',
        //     external: true,
        //     onlyMobile: true

        // }
    ]

    const [adminLoggedIn, setAdminLoggedIn] = React.useState(false);
    
    const adminLogin = () =>{
        setAdminLoggedIn(true);
    }

    const [hasUpdatedCounter, setHasUpdatedCounter] = React.useState(false);
    const updateCounter = ()  => {
        setHasUpdatedCounter(true);
    }


    const value : ConfigContextValue = {
        phoneNumber: '1234567890',
        phoneNumberFormatted: '(123) 456-7890',
        email: '',
        headerItems: menuItems,
        hasUpdatedCounter: hasUpdatedCounter,
        updateCounter: updateCounter,
        adminLoggedIn: adminLoggedIn,
        adminLogin: adminLogin
    }

   
    return (
        <ConfigContext.Provider value={value}>
            {props.children}
        </ConfigContext.Provider>
    )
}

export { ConfigContextProvider, ConfigContext}